import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Select, Space, Tooltip, Typography } from "antd";

import { optionsSearch } from "../../utils/search";
import { GRAY5, GRAY_PLACEHOLDER, WHITE } from "../../constant/colors";
import FloatLabel from "./FloatLabel";
import { DIAGNOSIS_FILTER_ISC_CODE, DIAGNOSIS_FILTER_NAME, FILTER_PATIENT_ASSIGNEE } from "../../constant/filters";

const commonStyle = {
  width: "100%",
  border: `0px`,
  borderBottom: `1px solid ${GRAY5}`,
  padding: 0
};

const { Text } = Typography;
const formatTooltipContent = (id, tooltipContent) => {
  if (id === FILTER_PATIENT_ASSIGNEE) {
    return tooltipContent;
  }

  if (!tooltipContent || tooltipContent.length === 0) {
    return "";
  }

  return tooltipContent
    .map((item) => {
      if (id === DIAGNOSIS_FILTER_ISC_CODE || id === DIAGNOSIS_FILTER_NAME) {
        return item.value;
      }
      return item.text || item.children;
    })
    .join(" | ");
};

const MultiSelect = ({
  children,
  maxTagCount = 1,
  selectAll = false,
  clearAll = false,
  useCommonStyle = true,
  onClearAll,
  onSelectAll,
  style = {},
  id,
  bordered = false,
  tooltipContent,
  setDiagnosisSearchResults = () => {},
  setDiagnosisIcdsSearchResults = () => {},
  ...props
}) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isHovering, setHovering] = useState(false);

  const formattedTooltipContent = formatTooltipContent(id, tooltipContent);

  return (
    <div id={id}>
      <Tooltip
        open={formattedTooltipContent && !isDropdownOpen && isHovering}
        title={<Text>{formattedTooltipContent}</Text>}
        color={WHITE}
        placement="right"
        overlayStyle={{
          maxWidth: "calc(100% - 600px)",
          whiteSpace: "normal",
          wordWrap: "break-word"
        }}
      >
        <Select
          {...props}
          mode="multiple"
          bordered={bordered}
          showArrow={true}
          allowClear={true}
          onClear={onClearAll}
          maxTagCount={maxTagCount}
          getPopupContainer={(trigger) => trigger.parentNode}
          onDropdownVisibleChange={(open) => {
            if (id === DIAGNOSIS_FILTER_ISC_CODE) {
              setDiagnosisIcdsSearchResults([]);
            }
            if (id === DIAGNOSIS_FILTER_NAME) {
              !open && setDiagnosisSearchResults([]);
            }
            setDropdownOpen(open);
          }}
          onMouseEnter={() => setHovering(true)}
          onMouseLeave={() => setHovering(false)}
          style={{
            ...(useCommonStyle && commonStyle),
            ...style,
            padding: props.label ? "10px 0 0 0" : ""
          }}
          dropdownRender={(menu) => (
            <div>
              {selectAll && clearAll && (
                <>
                  <div style={{ display: "flex", flexWrap: "nowrap", padding: 8, gap: 6 }}>
                    <Button block onClick={onSelectAll}>
                      {t("select_all")}
                    </Button>
                    <Button onClick={onClearAll}>{t("clear")}</Button>
                  </div>

                  <Divider style={{ margin: "4px 0" }} />
                </>
              )}
              {menu}
            </div>
          )}
        >
          {children}
        </Select>
      </Tooltip>
    </div>
  );
};

const SingleSelect = ({
  children,
  filterOption = optionsSearch,
  required = false,
  showSearch = true,
  bordered = false,
  useCommonStyle = true,
  style,
  ...props
}) => {
  return (
    <FloatLabel
      label={
        <Space size={4}>
          {required && <Text style={{ color: GRAY_PLACEHOLDER }}>*</Text>}
          <Text style={{ color: GRAY_PLACEHOLDER }}>{props.label}</Text>
        </Space>
      }
      name={props.label}
      value={props.value}
    >
      <Select
        bordered={bordered}
        filterOption={filterOption}
        showSearch={showSearch}
        style={{
          ...(useCommonStyle && commonStyle),
          padding: props.label ? "6px 0 0 0" : "",
          ...style
        }}
        placeholder={props.placeholder && (required ? `* ${props.placeholder}` : props.placeholder)}
        getPopupContainer={(trigger) => trigger.parentNode}
        {...props}
      >
        {children}
      </Select>
    </FloatLabel>
  );
};

export { SingleSelect, MultiSelect };
