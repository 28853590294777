import { takeLatest, select, fork, put, call } from "redux-saga/effects";
import ErrorMessage from "../../component/customComponent/customMessages/ErrorMessage";

import ACTION from "../action";
import { ApiNames } from "../../api/api";
import { selectUser, selectIDPConfig, selectOktaClientId } from "../selector";
import { callApi } from "./networkActions";
import { getAuthInstance, getSignInConfiguration, logout } from "@tailormed/common-client/services/auth";
import { oktaConfig } from "../../constant/okta";

// signInConfiguration function configures the sign-in process by fetching and setting sign-in configurations for Okta and IDP.
function* configureSignIn() {
  const signInConfiguration = yield getSignInConfiguration();
  if (!signInConfiguration) return;

  const oktaClientId = signInConfiguration?.okta_client_id;
  if (!oktaClientId) {
    console.log("Missing Okta Client ID");
    ErrorMessage("Server Error, Please contact support.");
    return; // Exit the saga if there's an error
  }

  // Set Okta configuration
  yield put(ACTION.setOktaConfig(oktaClientId));

  // Set IDP configuration directly after Okta configuration
  const idpConfig = { IDPEnabled: signInConfiguration.IDPEnabled, IDPLogoutURI: signInConfiguration.IDPLogoutURI };
  yield put(ACTION.setIDPConfig(idpConfig ?? { IDPEnabled: false }));
}

function* handleIDPFlow() {
  const idpConfig = yield select(selectIDPConfig);
  const user = yield select(selectUser);
  const oktaClientId = yield select(selectOktaClientId);
  const oktaAuth = getAuthInstance(oktaConfig.redirectUri, oktaClientId);

  if (idpConfig && idpConfig.IDPEnabled) {
    try {
      const session = yield call(oktaAuth.session.exists);
      if (session) {
        const response = yield call(oktaAuth.token.getWithoutPrompt);
        oktaAuth.tokenManager.setTokens(response.tokens);
      } else {
        if (user) {
          // Note: in case the session was killed from our Okta, need to logged the user out.
          yield call(logout, user, idpConfig, oktaConfig.loginURI);
        }
      }
    } catch (error) {
      console.error("failure in IDP flow", error);
    }
  }
}

function* getUser() {
  const loggedUserRes = yield callApi(ApiNames.LoggedUser);
  if (loggedUserRes && loggedUserRes.data) {
    const { data } = loggedUserRes;
    const { phiAllowed, siteLicense, users } = data;
    yield put(ACTION.setIsPhiAllowed(phiAllowed));
    yield put(ACTION.setEnvironment(siteLicense.siteName));
    yield put(ACTION.setTier(siteLicense.tier));
    yield put(ACTION.setUser(users[0]));
  } else {
    ErrorMessage(`User is not allowed`);
    const idpConfig = yield select(selectIDPConfig);
    const user = yield select(selectUser);
    yield call(logout, user, idpConfig, oktaConfig.loginURI);
  }
}

function* watchSignInActions() {
  yield takeLatest([ACTION.Types.SET_IDP_CONFIG, ACTION.Types.SET_USER], handleIDPFlow);
  yield fork(configureSignIn);
}

export { watchSignInActions, getUser };
