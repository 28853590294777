import { apiSortOptions } from "./sort";
import { PLAN_MARKET_FILTER_OPTIONS } from "./planMarkets";
import { INTERNAL_PROGRAM } from "./assistanceProgram";
import { APPLICATION_FORM_SIGNATURE_STATUS_FILTER_OPTIONS } from "./applicationFormSignatureEnrollment";

export const DRUG_SEARCH_OPTIONS = {
  SEARCH_ALL_DRUGS: "searchDrugs"
};

export const ALL = "all";
export const ALL_TIMES = "all_times";
export const DATE_RANGE = "date_range";
export const UNASSIGNED = "unassigned";
// PAST
export const LAST_3_DAYS = "last_3_days";
export const LAST_WEEK = "last_week";
export const LAST_MONTH = "last_month";
export const LAST_2_MONTHS = "last_2_months";
export const LAST_3_MONTHS = "last_3_months";
// FUTURE
export const TODAY = "today";
export const NEXT_2_DAYS = "next_2_days";
export const NEXT_3_DAYS = "next_3_days";
export const NEXT_7_DAYS = "next_7_days";
export const NEXT_30_DAYS = "next_30_days";
export const LAST_90_DAYS = "last_90_days";
export const THIS_WEEK = "this_week";
export const THIS_MONTH = "this_month";
export const FOLLOWING_WEEK = "following_week";
export const WITHIN_A_WEEK = "weekly";
export const WITHIN_A_MONTH = "monthly";
export const NEXT_MONTH = "next_month";

export const CREATED_AT_ASCEND = "createdAt:ASC";
export const CREATED_AT_DESCEND = "createdAt:DESC";
export const ORIGINAL_FILENAME_ASCEND = "originalFilename:ASC";
export const ORIGINAL_FILENAME_DESCEND = "originalFilename:DESC";

export const FN = "fn";
export const PHARMACY = "pharmacy";

export const FREE_DRUGS = "free-drugs";
export const COPAYS = "copays";
export const LIVING_EXPENSES = "living-expenses";
export const GOVERNMENT_PLANS = "government-plans";
export const OTHER = "other";
export const INDEFINITELY = "indefinitely";

export const maxStartSearchCharactersLength = 3;

export const dateFilterTypes = {
  pastFilters: [
    { title: "Last 3 Days", value: LAST_3_DAYS },
    { title: "Last Week", value: LAST_WEEK },
    { title: "Last Month", value: LAST_MONTH }
  ],
  followUpFilters: [
    { title: "Today", value: TODAY },
    { title: "This Week", value: THIS_WEEK },
    { title: "Following Week", value: FOLLOWING_WEEK }
  ],
  claimDateFilters: [
    { title: "Last Week", value: LAST_WEEK },
    { title: "Last Month", value: LAST_MONTH },
    { title: "Next 7 Days", value: NEXT_7_DAYS },
    { title: "Next 30 Days", value: NEXT_30_DAYS }
  ],
  claimFollowupDateFilters: [
    { title: "Today", value: TODAY },
    { title: "Next 7 Days", value: NEXT_7_DAYS },
    { title: "Next 30 Days", value: NEXT_30_DAYS }
  ],
  claimSubmittedDateFilters: [
    { title: "Last Week", value: LAST_WEEK },
    { title: "Last Month", value: LAST_MONTH },
    { title: "Last 90 Days", value: LAST_90_DAYS }
  ],
  dateOfServiceFilters: [
    { title: "Last Week", value: LAST_WEEK },
    { title: "Last Month", value: LAST_MONTH },
    { title: "Next 7 Days", value: NEXT_7_DAYS },
    { title: "Next 30 Days", value: NEXT_30_DAYS }
  ],
  claimReceivedDateFilters: [
    { title: "Last Week", value: LAST_WEEK },
    { title: "Last Month", value: LAST_MONTH },
    { title: "Last 90 Days", value: LAST_90_DAYS }
  ],
  visitScheduleDateRangeFilters: [
    { title: "Next 3 Days", value: NEXT_3_DAYS },
    { title: "Next 2 Days", value: NEXT_2_DAYS },
    { title: "Today", value: TODAY }
  ],
  validThroughDateRangeFilters: [
    { title: "Last Month", value: LAST_MONTH },
    { title: "Last Week", value: LAST_WEEK },
    { title: "Next Month", value: NEXT_MONTH }
  ],
  validThroughDateRangeAllApplicationFilters: [
    { title: "Last Month", value: LAST_MONTH },
    { title: "Last Week", value: LAST_WEEK },
    { title: "Next Month", value: NEXT_MONTH },
    { title: "Indefinitely", value: INDEFINITELY }
  ],
  medicationCreatedDateFilters: [
    { title: "Today", value: TODAY, id: TODAY },
    { title: "Last 3 Days", value: LAST_3_DAYS, id: LAST_3_DAYS },
    { title: "Last Week", value: LAST_WEEK, id: LAST_WEEK },
    { title: "Last Month", value: LAST_MONTH, id: LAST_MONTH },
    { title: "Last 3 Months", value: LAST_3_MONTHS, id: LAST_3_MONTHS }
  ],
  approvedDateFilters: [
    { title: "Last Week", value: LAST_WEEK },
    { title: "Last Month", value: LAST_MONTH },
    { title: "Last 2 Months", value: LAST_2_MONTHS }
  ]
};

export const defaultFilters = {
  starredPatients: false,
  inActiveTreatment: false,
  hideDeceasedPatients: false,
  expressEnrollEligible: false,
  claimExists: null,
  groupPatientVisits: false,
  insuranceCombination: false,
  isUnassigned: false,
  internalProgram: false,
  dates: {
    patientCreationDateRange: {
      fromDate: null,
      toDate: null,
      value: ALL_TIMES
    },
    visitScheduleDateRange: {
      fromDate: null,
      toDate: null,
      value: NEXT_3_DAYS
    },
    followupDate: {
      fromDate: null,
      toDate: null,
      value: ALL_TIMES
    },
    claimDate: {
      fromDate: null,
      toDate: null,
      value: ALL_TIMES
    },
    claimFollowupDate: {
      fromDate: null,
      toDate: null,
      value: ALL_TIMES
    },
    claimSubmittedDate: {
      fromDate: null,
      toDate: null,
      value: ALL_TIMES
    },
    dateOfService: {
      fromDate: null,
      toDate: null,
      value: ALL_TIMES
    },
    claimReceivedDate: {
      fromDate: null,
      toDate: null,
      value: ALL_TIMES
    },
    coverageChangeDate: {
      fromDate: null,
      toDate: null,
      value: ALL_TIMES
    },
    medicationCreatedDate: {
      fromDate: null,
      toDate: null,
      value: ALL_TIMES
    },
    activeDate: {
      fromDate: null,
      toDate: null,
      value: ALL_TIMES
    },
    validThroughDateRange: {
      fromDate: null,
      toDate: null,
      value: ALL_TIMES
    },
    approvedDateRange: {
      fromDate: null,
      toDate: null,
      value: ALL_TIMES
    }
  },
  multiples: {
    planMarketTypes: {},
    ageRanges: [],
    statuses: {},
    claimStatuses: {},
    assignees: {},
    facilities: {},
    claimFacilities: {},
    visitFacilities: {},
    doctors: {},
    drugRoutes: {},
    diagnosisTypes: {},
    rxnormDrugs: [],
    claimRxnormDrugs: [],
    diagnosisNames: {},
    diagnosisCodeIDs: {},
    potentialSavingsTypes: {},
    potentialSavingsDrugRoutes: {},
    potentialSavingsAPSponsor: {},
    potentialSavingsAplicationStatuses: {},
    topOppAPStatuses: {},
    importedStatuses: [],
    applicationSignatureStatuses: []
  },
  numbers: {
    remainingOOP: 0,
    applicationFundBalance: 0
  }
};

export const defaultFiltersInputs = {
  ageRanges: [],
  statuses: [],
  claimStatuses: [],
  assignees: [],
  isUnassigned: false,
  claimExists: null,
  facilities: [],
  claimFacilities: [],
  visitFacilities: [],
  doctors: [],
  planMarketTypes: [],
  rxnormDrugs: [],
  claimRxnormDrugs: [],
  drugRoutes: [],
  diagnosisTypes: [],
  importedStatuses: [],
  diagnosisNames: [],
  diagnosisCodeIDs: [],
  potentialSavingsTypes: [],
  potentialSavingsDrugRoutes: [],

  potentialSavingsAplicationStatuses: [],
  potentialSavingsAPSponsor: [],
  topOppAPStatuses: [],
  patientCreationDateRange: ALL_TIMES,
  visitScheduleDateRange: NEXT_3_DAYS,
  followupDate: ALL_TIMES,
  claimDate: ALL_TIMES,
  claimFollowupDate: ALL_TIMES,
  claimSubmittedDate: ALL_TIMES,
  claimReceivedDate: ALL_TIMES,
  coverageChangeDate: ALL_TIMES,
  medicationCreatedDate: ALL_TIMES,
  activeDate: ALL_TIMES,
  validThroughDateRange: ALL_TIMES,
  approvedDateRange: ALL_TIMES,
  applicationSignatureStatuses: []
};

export const NOT_DONE = null;
export const DONE = "checked";

export const defaultTaskFilters = {
  timeRange: { fromDate: ALL_TIMES, toDate: null },
  assigneesFilter: [],
  status: NOT_DONE
};

export const defaultSavedListFilters = Object.freeze({
  query: "",
  limit: 10,
  skip: 0,
  orderBy: "updatedAt",
  filterBy: "all",
  sortBy: "DESC"
});

export const defaultSavedListSorting = {
  all: { name: apiSortOptions.desc, updatedAt: apiSortOptions.desc, orderBy: defaultSavedListFilters.orderBy },
  private: { name: apiSortOptions.desc, updatedAt: apiSortOptions.desc, orderBy: defaultSavedListFilters.orderBy },
  starred: { name: apiSortOptions.desc, updatedAt: apiSortOptions.desc, orderBy: defaultSavedListFilters.orderBy }
};

export const tableFilterValues = {
  drugRoute: [
    { key: "1", value: "IV", translationText: "iv" },
    { key: "2", value: "Non-IV", translationText: "non_iv" }
  ],
  pendingRenewalAPStatuses: [
    { value: "approved", translationText: "Approved", localSortKey: 4 },
    { value: "expired", translationText: "Expired", localSortKey: 5 },
    { value: "exhausted", translationText: "exhausted", localSortKey: 6 }
  ],
  claimExists: [
    { key: "1", value: true, translationText: "tableFilters.exists" },
    { key: "2", value: false, translationText: "tableFilters.doesnt_exists" }
  ],
  allApplicationsAPStatuses: [
    { translationText: "patientApplications.prepare", value: "prepare", localSortKey: 1 },
    { translationText: "patientApplications.applied", value: "applied", localSortKey: 2 },
    {
      translationText: "patientApplications.applied_to_waitlist",
      key: "1",
      value: "applied to waitlist",
      localSortKey: 3
    },
    { translationText: "patientApplications.approved", value: "approved", localSortKey: 4 },
    { translationText: "patientApplications.expired", value: "expired", localSortKey: 5 },
    { translationText: "patientApplications.exhausted", value: "exhausted", localSortKey: 6 },
    { translationText: "patientApplications.canceled", value: "canceled", localSortKey: 7 },
    { translationText: "patientApplications.denied", value: "rejected", localSortKey: 8 },
    { translationText: "patientApplications.ineligible", value: "ineligible", localSortKey: 9 },
    { translationText: "patientApplications.not_interested", value: "not-interested", localSortKey: 10 },
    { translationText: "patientApplications.not_returned", value: "not-returned", localSortKey: 11 },
    { translationText: "patientApplications.missing_information", value: "missing information", localSortKey: 12 },
    { translationText: "patientApplications.temporarily_approved", value: "temporarily approved", localSortKey: 13 }
  ],
  diagnosisType: [
    { key: "1", value: "cancer", translationText: "cancer" },
    { key: "2", value: "non-cancer", translationText: "non_cancer" }
  ],
  planMarketTypes: PLAN_MARKET_FILTER_OPTIONS,
  potentialSavingsTypes: [
    { key: "3", value: "3", translationText: "foundation_copay_card" },
    { key: "2", value: "2", translationText: "free_drug" },
    { key: "5", value: "5", translationText: "government_plan" },
    { key: "4", value: "4", translationText: "living_and_transport_expenses" },
    { key: "1", value: "1", translationText: "manufacturere_copay_card" },
    { key: INTERNAL_PROGRAM, value: INTERNAL_PROGRAM, translationText: "internal" }
  ],
  claimPotentialSavingsTypes: [
    { key: "3", value: "3", translationText: "foundation_copay_card" },
    { key: "1", value: "1", translationText: "manufacturere_copay_card" }
  ],
  patientAge: [
    { key: "10", value: "1", translationText: "0-18", id: "0_18" },
    { key: "20", value: "2", translationText: "18-65", id: "18_65" },
    { key: "30", value: "3", translationText: "65", id: "65" },
    { key: "40", value: "4", translationText: "65+", id: "65_plus" }
  ],
  applicationSignatureStatuses: APPLICATION_FORM_SIGNATURE_STATUS_FILTER_OPTIONS
};

export const administrationType = [
  { key: "1", value: "outpatient", translationText: "Outpatient" },
  { key: "2", value: "clinic", translationText: "Clinic" },
  { key: "3", value: "self", translationText: "Self" }
];

export const assistanceProgramStatus = [
  { key: "1", value: "open", translationText: "Open" },
  { key: "2", value: "waitlist", translationText: "Wait List" }
];

export const defaultRelevantPatientsFilters = {
  statuses: [],
  diagnosisNames: [],
  diagnosisCodeIDs: [],
  assignees: [],
  facilities: [],
  doctors: [],
  isUnassigned: false
};

export const MainBucketsTrackEvents = Object.freeze({
  BUCKET_FILTER_CHANGED: "bucket_filter_changed",
  BUCKET_COLUMN_SELECTOR_SELECTED: "bucket_column_selector_selected"
});

export const FILTER_PATIENT_ASSIGNEE = "filter_patient_assignee";
export const DIAGNOSIS_FILTER_ISC_CODE = "diagnosis_filter_icd_code";
export const DIAGNOSIS_FILTER_NAME = "diagnosis_filter_name";
