import { DRUGS } from "../novartisSectionsController";

export const getConditionOptions = (associatedDrug, isBelow18 = false) => {
  switch (associatedDrug) {
    case DRUGS.COSENTYX:
      return isBelow18 ? primaryConditionOptions.cosentyx.below18 : primaryConditionOptions.cosentyx.above18;
    case DRUGS.KISQALI:
      return primaryConditionOptions.kisqali;
    default:
      return [];
  }
};
const primaryConditionOptions = {
  cosentyx: {
    below18: [
      { value: "Pediatric Plaque Psoriasis", displayValue: "Pediatric Plaque Psoriasis" },
      { value: "Enthesitis-related arthritis", displayValue: "Enthesitis-related Arthritis (ERA)" },
      { value: "Juvenile Psoriatic Arthritis (JPsA)", displayValue: "Juvenile Psoriatic Arthritis (JPsA)" }
    ],
    above18: [
      { value: "Moderate to Severe Plaque Psoriasis", displayValue: "Moderate to Severe Plaque Psoriasis" },
      {
        value: "Hidradenitis Suppurativa",
        displayValue: "Moderate to Severe Hidradenitis Suppurativa"
      },
      { value: "Psoriatic Arthritis", displayValue: "Psoriatic Arthritis" },
      {
        value: "Moderate to Severe Plaque Psoriasis and Psoriatic Arthritis",
        displayValue: "Moderate to Severe Plaque Psoriasis & Psoriatic Arthritis"
      },
      { value: "Ankylosing Spondylitis", displayValue: "Ankylosing Spondylitis" },
      { value: "Non-Radiographic Axial Spondyloarthritis", displayValue: "Non-Radiographic Axial Spondyloarthritis" }
    ]
  },
  kisqali: [{ value: "HR+/HER2- Metastatic Breast Cancer", displayValue: "HR+/HER2- Metastatic Breast Cancer" }]
};
