import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { debounce } from "lodash";
import { createStructuredSelector } from "reselect";
import { Space, Table, Typography, Input } from "antd";
import { withTranslation } from "react-i18next";
import moment from "moment-timezone";
import { withRouter } from "react-router";
import { SearchOutlined } from "@ant-design/icons";
import styled from "styled-components";

import SuccessMessage from "../../component/customComponent/customMessages/SuccessMessage";
import ErrorMessage from "../../component/customComponent/customMessages/ErrorMessage";

import {
  selectTableColumns,
  selectIsFetching,
  selectSavedListData,
  selectedFilterListType,
  selectedFilterListQuery,
  selectedFilterSorting,
  selectedSavedListActiveColumn
} from "../../store/selector";
import { ApiNames, deleteSavedListAPI, getNumberOfPatientsAPI, parseSearchFormParams } from "../../api/api";
import ACTION from "../../store/action";

import { TailormedSpinner } from "../TailormedSpinner/TailormedSpinner";
import { ListNameColumn, NumberOfRecordsColumn } from "./CommonColumns";
import { ASCEND, DECEND, apiSortOptions } from "../../constant/sort";
import { dateSort, stringSort } from "../../utils/sort";

import { SAVED_LIST_PAGE_SIZE, selectedViews } from "../../constant/table";
import { fontWeights, sizes } from "../../constant/styles";

import { ReactComponent as DeleteIcon } from "../../assets/svg/delete-new-icon.svg";
import { ReactComponent as NoSavedListFoundIcon } from "../../assets/svg/no-saved-list-found.svg";
import { ReactComponent as NoFavoriteListFoundIcon } from "../../assets/svg/no-favorite-list-found.svg";

import { filtersTypes, filtersTypesOptions } from "../../constant/savedList";
import { RadioGroup } from "../UI/Radio/Group";

import { SavedListActionsMenu } from "../SavedList/SavedListActionsMenu";
import { ModalComponent } from "../ModalComponent";
import { StarFilterListColumn } from "./CommonColumns";
import { Routes } from "../../constant/routes";
import { DARK_GRAY3 } from "../../constant/colors";
import { defaultSavedListFilters } from "../../constant/filters";
import SavedListPageCount from "./SavedListPageCount";
import { commonTableStyle } from "./TableStyle";
import { scrollTableToTop } from "../../utils/fetchTableData";

const { Text } = Typography;

const StyledNoListSpace = styled(Space)`
  margin-left: -20px;
`;
const StyledNoListTitle = styled(Text)`
  font-weight: ${fontWeights.semibold};
  font-size: ${sizes.large};
`;
const StyledNoListDescription = styled(Text)`
  color: ${DARK_GRAY3};
  font-size: ${sizes.medium};
  white-space: normal;
`;

const StyledTable = styled(Table)`
  ${commonTableStyle()}
  .ant-table-empty {
    margin-top: 10px;
  }

  .ant-table-column-sorter {
    margin-left: 10px;
  }
`;
const SavedListTableComponent = ({
  tableColumns,
  isFetching,
  history,
  tableData,
  searchSavedListAct,
  setListNameQueryAct,
  setSelectedFilterTypeAct,
  selectedListType,
  setSavedListSortByAct,
  setSavedListOrderByAct,
  activeTabSorting,
  searchQuery,
  activeColumn,
  t
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [currentPage, setCurrentPage] = useState(tableData?.selectedPage || 1);
  const [savedListsRecordCounts, setSavedListsRecordCounts] = useState({});

  const sortTooltips = {
    [ASCEND]: t("savedList.sort_descend_tooltip"),
    [DECEND]: t("savedList.sort_cancel_tooltip"),
    null: t("savedList.sort_ascend_tooltip")
  };

  const getNextSortTooltip = (columnName) => sortTooltips[getSortOrder(columnName)];

  useEffect(() => {
    searchSavedListAct(currentPage);
  }, [selectedListType, searchQuery]);

  const fetchDataCount = async (savedList) => {
    const selectedView =
      savedList.selectedView === selectedViews.highPriority ? selectedViews.topOpportunity : savedList.selectedView;
    const params = JSON.parse(savedList?.params);
    const formData = parseSearchFormParams({
      selectedView: selectedView,
      page: defaultSavedListFilters.skip,
      limit: defaultSavedListFilters.limit,
      filters: params?.filters
    });

    try {
      const { data } = await getNumberOfPatientsAPI(
        formData?.filters,
        defaultSavedListFilters.skip,
        defaultSavedListFilters.limit,
        selectedView
      );

      setSavedListsRecordCounts((prevState) => ({
        ...prevState,
        [savedList.id]: {
          success: true,
          total: data?.totalPatients
        }
      }));
    } catch (error) {
      setSavedListsRecordCounts((prevState) => ({
        ...prevState,
        [savedList.id]: {
          total: null,
          success: false
        }
      }));
    }
  };

  useEffect(() => {
    if (tableData?.data) {
      tableData.data.forEach(fetchDataCount);
    }
  }, [tableData]);

  const onClickTable = () => ({
    onCell: (list) => ({
      onClick: () => {
        const { id, isValid } = list;
        if (!isValid) {
          return;
        } else {
          history.push(`${Routes.FILTER_LIST}/${id}`, { from: selectedViews.savedList });
        }
      }
    })
  });

  const getSortOrder = (columnName) => {
    if (activeColumn.name !== columnName) return null;
    return activeTabSorting[columnName] === apiSortOptions.asc ? ASCEND : DECEND;
  };

  const columns = [
    {
      dataIndex: tableColumns[selectedViews.savedList]?.star?.dataIndex,
      width: 35,
      key: tableColumns[selectedViews.savedList]?.star?.key,
      render: (text, record) => <StarFilterListColumn page={currentPage} record={record} />
    },
    {
      ...tableColumns[selectedViews.savedList]?.name,
      render: (text, record) => <ListNameColumn record={record} />,
      sorter: {
        compare: (a, b, direction) => stringSort(a.name, b.name, direction)
      },
      sortDirections: [ASCEND, DECEND, null],
      sortOrder: getSortOrder("name"),
      ...onClickTable(),
      showSorterTooltip: {
        placement: "top",
        title: getNextSortTooltip("name"),
        align: { targetOffset: ["45%", "0"] }
      }
    },
    {
      ...tableColumns[selectedViews.savedList]?.updatedAt,
      render: (text, record) => moment(record.updatedAt).format("MMM D, YYYY"),
      sorter: {
        compare: (a, b, direction) => dateSort(a.updatedAt, b.updatedAt, direction)
      },
      width: 150,
      sortDirections: [ASCEND, DECEND, null],
      sortOrder: getSortOrder("updatedAt"),
      ...onClickTable(),
      showSorterTooltip: {
        placement: "top",
        title: getNextSortTooltip("updatedAt"),
        align: { targetOffset: ["10%", "0"] }
      }
    },
    {
      dataIndex: tableColumns[selectedViews.savedList]?.numberOfRecords,
      key: tableColumns[selectedViews.savedList]?.numberOfRecords?.key,
      title: tableColumns[selectedViews.savedList]?.numberOfRecords?.title,
      width: 150,
      render: (text, record) => (
        <NumberOfRecordsColumn record={record} savedListsRecordCounts={savedListsRecordCounts} />
      )
    },
    {
      ...tableColumns[selectedViews.savedList]?.actions?.tableColumns,
      width: 10,
      render: (text, record) => (
        <SavedListActionsMenu
          record={record}
          deleteAction={() => {
            setSelectedRow(record);
            setShowDeleteModal(true);
          }}
          source="saved_lists"
        />
      )
    }
  ];

  const onFilterChange = (e) => {
    setCurrentPage(1);
    setSelectedFilterTypeAct(e.target.value);
  };
  const onTableChange = (pagination, filters, sorter) => {
    const { current: nextPage } = pagination;
    if (nextPage === currentPage) {
      const sortedColumns = Array.isArray(sorter) ? sorter : [sorter];

      if (!sortedColumns.length || !sortedColumns[0].order) {
        setCurrentPage(1);
        setSavedListOrderByAct(null);
        setSavedListSortByAct(null);
        searchSavedListAct(1);
        return;
      }

      const activeSortedColumn = sortedColumns[0];
      let { order: nextSortBy, column: { key: nextColumnName } = {} } = activeSortedColumn;

      nextColumnName = nextColumnName === "listName" ? "name" : "updatedAt";
      const columnIschanged = nextColumnName !== activeColumn.name;

      nextSortBy = nextSortBy === DECEND ? apiSortOptions.desc : apiSortOptions.asc;
      const prevSortBy = activeTabSorting[nextColumnName];
      const sortDirectionIschanged = prevSortBy !== nextSortBy;

      if (columnIschanged || sortDirectionIschanged) {
        setCurrentPage(1);
        setSavedListOrderByAct(nextColumnName);
        setSavedListSortByAct(nextSortBy);
        searchSavedListAct(1);
      }
    }
    scrollTableToTop();
  };

  const onSearchHandler = (e) => {
    setCurrentPage(1);
    setListNameQueryAct(e.target.value);
  };

  const debouncedSearchChangeHandler = useCallback(debounce(onSearchHandler, 500), []);

  return (
    <div style={{ width: "100%" }}>
      <ModalComponent
        open={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        okText={t("savedList.confirm_modal.ok_text")}
        onOk={async () => {
          try {
            const deleteListRes = await deleteSavedListAPI(selectedRow?.id);
            if (deleteListRes) {
              let nextExpectedPage = currentPage;
              const isLastRecord = tableData.total - 1 === 0;
              if ((tableData.total - 1) % SAVED_LIST_PAGE_SIZE === 0 && !isLastRecord && currentPage > 1)
                nextExpectedPage = currentPage - 1;

              setCurrentPage(nextExpectedPage);

              searchSavedListAct(nextExpectedPage);

              setShowDeleteModal(false);

              SuccessMessage(t("savedList.confirm_modal.success_message"));
            }
          } catch (error) {
            ErrorMessage(t("savedList.confirm_modal.error_message"));
          }
        }}
      >
        <Space direction="vertical">
          <DeleteIcon style={{ width: "48px" }} />
          <Text className="title">{t("savedList.confirm_modal.title")}</Text>
          <Text className="content">
            {t("savedList.confirm_modal.content", {
              listName: selectedRow?.name
            })}
          </Text>
        </Space>
      </ModalComponent>

      <Space style={{ justifyContent: "space-between", display: "flex" }}>
        <Input
          style={{ width: "400px" }}
          placeholder={t("savedList.search_placeholder")}
          defaultValue={searchQuery}
          allowClear
          prefix={<SearchOutlined />}
          id="saved_lists_search_bar"
          onChange={debouncedSearchChangeHandler}
        />
        <RadioGroup
          onChange={onFilterChange}
          options={filtersTypesOptions}
          defaultValue={selectedListType || filtersTypes.all}
          buttonStyle="solid"
          t={t}
          id="saved_lists_all_options_lists"
        />
      </Space>

      <StyledTable
        size="small"
        rowClassName={({ isValid }) => (!isValid ? "disabled-row" : "cursor-pointer")}
        loading={{ indicator: <TailormedSpinner />, spinning: isFetching }}
        columns={columns}
        onChange={onTableChange}
        locale={{
          emptyText:
            tableData &&
            tableData.data?.length == 0 &&
            (selectedListType === filtersTypes.starred ? (
              <StyledNoListSpace direction={"vertical"} size={10}>
                <NoFavoriteListFoundIcon />
                <StyledNoListTitle>
                  {t(searchQuery === "" ? "no_favorite_to_show" : "no_saved_lists_found")}
                </StyledNoListTitle>
                {searchQuery === "" && (
                  <StyledNoListDescription>{t("no_favorite_description")}</StyledNoListDescription>
                )}
              </StyledNoListSpace>
            ) : (
              <StyledNoListSpace direction={"vertical"} size={10}>
                <NoSavedListFoundIcon />
                <StyledNoListTitle>
                  {t(searchQuery === "" ? "no_saved_lists_to_show" : "no_saved_lists_found")}
                </StyledNoListTitle>
                {searchQuery === "" && (
                  <StyledNoListDescription>{t("no_saved_lists_to_show_description")}</StyledNoListDescription>
                )}
              </StyledNoListSpace>
            ))
        }}
        pagination={{
          size: "small",
          showSizeChanger: false,
          current: currentPage,
          onChange: (page) => {
            setCurrentPage(page);
            searchSavedListAct(page);
            window.scrollTo(0, 0);
          },
          defaultPageSize: SAVED_LIST_PAGE_SIZE,
          pageSize: SAVED_LIST_PAGE_SIZE,
          position: ["bottom"],
          total: tableData?.total,
          showTotal: (total, range) => (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <SavedListPageCount total={total} range={range} isAccurateCount={true} />
            </div>
          )
        }}
        dataSource={
          tableData && tableData.data.length > 0
            ? tableData.data
                .map((list, index) => {
                  return {
                    ...list,
                    key: index
                  };
                })
                .sort((a, b) => b.isActive - a.isActive)
            : null
        }
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  isFetching: selectIsFetching([ApiNames.searchSavedList]),
  tableData: selectSavedListData,
  selectedListType: selectedFilterListType,
  searchQuery: selectedFilterListQuery,
  activeTabSorting: selectedFilterSorting,
  activeColumn: selectedSavedListActiveColumn
});

const mapDispatchToProps = (dispatch) => ({
  searchSavedListAct: (page) => dispatch(ACTION.searchSavedListAction(page)),
  setListNameQueryAct: (query) => dispatch(ACTION.setListNameQueryAction(query)),
  setSelectedFilterTypeAct: (listType) => dispatch(ACTION.setSelectedFilterListType(listType)),
  setSavedListSortByAct: (sortBy) => dispatch(ACTION.updateSavedListSortBy(sortBy)),
  setSavedListOrderByAct: (orderBy) => dispatch(ACTION.updateSavedListOrderBy(orderBy))
});

const SavedListTable = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(SavedListTableComponent);

export { SavedListTable };
