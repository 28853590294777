import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { Table } from "antd";
import { withTranslation } from "react-i18next";
import moment from "moment-timezone";
import { withRouter } from "react-router";
import "./cursor.less";
import styled from "styled-components";

import {
  selectTableColumns,
  selectIsFetching,
  selectAllPatientsData,
  selectSearchedPatients
} from "../../store/selector";
import { ApiNames } from "../../api/api";
import ACTION from "../../store/action";
import { PAGE_SIZE, selectedViews } from "../../constant/table";
import { TailormedSpinner } from "../TailormedSpinner/TailormedSpinner";
import { EditColumn, PatientNameColumn, StarColumn, withSorting, AssigneeColumn } from "./CommonColumns";
import PatientPageCount from "./PatientPageCount";
import { TabsMap } from "../../screen/patient/Patient";
import { ColumnSelectorContainer, commonTableStyle } from "./TableStyle";
import { patientInsuranceCell, PatientStatusCell } from "../TableCells";
import { SUPPORTED_COVERAGES } from "../../constant/patient";
import { dateFormatter, DATE_FORMAT } from "../../utils/date";
import { formatPhoneNumber, formatSSN } from "../../utils/formaters";
import { DeceasedLable } from "../DeceasedLable";
import { ColumnSelector } from "../ColumnSelector";
import EmptyTableMessage from "./EmptyTableMessage";
import { fetchTableData } from "../../utils/fetchTableData";

const StyledTable = styled(Table)`
  ${(props) => commonTableStyle(props.isFilterListView)}
  .ant-table-thead > tr > th:nth-last-of-type(-n + 2)::before {
    display: none;
  }

  .ant-table {
    max-height: ${({ isSearch }) => {
      if (isSearch) return "calc(100vh - 262px)";
    }};
  }

  .ant-table-empty {
    margin: ${({ isSearch }) => {
      if (isSearch) return "20px 0px 40px 0px;";
    }};

    .ant-table-tbody {
      height: ${({ isSearch }) => {
        if (isSearch) return "calc(100vh - 300px)";
      }};
    }
  }

  margin-top: ${({ isSearch }) => {
    if (isSearch) return "0px";
  }};
`;

const AllPatientsTableComponent = ({
  fetchOrders,
  isSearch,
  tableColumns,
  isFetching,
  setTableDataAct,
  history,
  tableData,
  tableDataWithSearch,
  isFetchingWithSearch,
  t,
  updateSavedFilterViewParameters = undefined,
  isFilterListView
}) => {
  const [hoveringLineIndex, setHoveringLineIndex] = useState(null);

  const fetchData = (pagination, filters, sorter) => {
    const targetData = isSearch ? tableDataWithSearch : tableData;
    fetchTableData(
      pagination,
      sorter,
      targetData,
      setTableDataAct,
      updateSavedFilterViewParameters,
      isSearch,
      fetchOrders
    );
  };

  const onClickTable = () => ({
    onCell: (patient) => ({
      onClick: () => {
        const { patientId, journeyId } = patient;
        history.push(TabsMap.overview.path(patientId, journeyId));
      }
    })
  });

  const {
    patients: searchedPatients = [],
    totalPatients,
    selectedPage: searchResultCurrentPage
  } = tableDataWithSearch || {};

  const currentPage = isSearch ? searchResultCurrentPage : tableData && tableData.selectedPage;
  const totalRecordsCount = isSearch ? totalPatients : tableData?.totalPatients;
  const sortingEnabled = isSearch ? tableDataWithSearch?.sortingEnabled : tableData?.sortingEnabled;

  const columns = [
    {
      dataIndex: tableColumns[selectedViews.allPatients]?.star?.dataIndex,
      key: tableColumns[selectedViews.allPatients]?.star?.key,
      width: 35,
      render: (text, record) => <StarColumn record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.name_id, {
      sorterTooltipText: t("columns.sortById"),
      render: (text, record) => <PatientNameColumn record={record} />,
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.isDeceased, {
      render: (_, record) => (record.isDeceased ? <DeceasedLable /> : ""),
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.allPatients]?.phone_number,
      render: (text, record) => formatPhoneNumber(record.phone_number || record.home_number),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.dob,
      render: (text, record) => {
        if (!record.dob || record.dob === t("invalid_date")) {
          return "";
        } else {
          const dob = moment(record.dob);
          if (dob.isValid()) {
            return dob.format(DATE_FORMAT);
          } else {
            return "";
          }
        }
      },
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.ssn,
      render: (text, record) => formatSSN(record.social_security_number),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.primaryDiagnosis,
      render: (text, record) => (record.diagnosises[0] ? record.diagnosises[0] : null),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.assignee,
      width: 230,
      render: (text, record) => <AssigneeColumn record={record} />,
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.facility, {
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.physician, {
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.allPatients]?.employer,
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.creationDate, {
      render: (text, record) => moment(record.creationDate).format("MMM D, YYYY"),
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.allPatients]?.journeyEvent,
      render: (text, record) => (
        <div style={{ display: "flex" }}>
          <div>
            <img src={`/images/events/${record.journeyUpcomingEventIcon}`} alt="" />
          </div>
          <div>
            <div style={{ textTransform: "capitalize" }}>{record.journeyUpcomingEventChapter}</div>
            <div style={{ color: "#9b9b9b" }}>{record.journeyUpcomingEvent}</div>
          </div>
        </div>
      ),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.primaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.primary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.secondaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.secondary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.tertiaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.tertiary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.allPatients]?.agreementSignatureDate,
      render: (text, record) => record.agreement_signature_date && dateFormatter(record.agreement_signature_date)
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.patientStatus, {
      render: (_, record) => <PatientStatusCell value={record.status} />,
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.followupDate, {
      render: (text, record) => record.followupDate && dateFormatter(record.followupDate)
    }),
    {
      dataIndex: tableColumns[selectedViews.allPatients]?.edit?.dataIndex,
      key: tableColumns[selectedViews.allPatients]?.edit?.key,
      width: 35,
      render: (_, record, rowIndex) => <EditColumn isHovering={hoveringLineIndex === rowIndex} record={record} />
    }
  ];

  return (
    <>
      {((!isSearch && !!tableData?.patients.length) || (isSearch && !!searchedPatients.length)) && (
        <ColumnSelectorContainer isSearchResultsView={isSearch}>
          <ColumnSelector isSearchResultsView={false} />
        </ColumnSelectorContainer>
      )}

      <StyledTable
        size="small"
        isSearch={isSearch}
        rowClassName="cursor-pointer"
        style={{ width: "100%" }}
        isFilterListView={isFilterListView}
        loading={{ indicator: <TailormedSpinner />, spinning: (isSearch && isFetchingWithSearch) || isFetching }}
        columns={columns.filter((col) => tableColumns[selectedViews.allPatients]?.[col.key]?.display)}
        locale={{
          emptyText: <EmptyTableMessage />
        }}
        onRow={(_, rowIndex) => {
          return {
            onMouseEnter: () => setHoveringLineIndex(rowIndex),
            onMouseLeave: () => setHoveringLineIndex(null)
          };
        }}
        pagination={{
          size: "small",
          showSizeChanger: false,
          current: currentPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          position: ["bottom"],
          total: totalRecordsCount,
          showTotal: (total, range) => (
            <PatientPageCount
              total={total}
              range={range}
              isAccurateCount={isSearch ? totalPatients : tableData.isAccurateCount}
            />
          )
        }}
        onChange={fetchData}
        dataSource={
          !isSearch
            ? tableData && tableData.patients
              ? tableData.patients
                  .map((patient, index) => ({ ...patient, key: index }))
                  .sort((a, b) => b.isActive - a.isActive)
              : null
            : searchedPatients.length
            ? searchedPatients
                .map((patient, index) => ({ ...patient, key: index }))
                .sort((a, b) => b.isActive - a.isActive)
            : null
        }
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  tableData: selectAllPatientsData,
  tableDataWithSearch: selectSearchedPatients,
  isFetching: selectIsFetching([ApiNames.AllPatientsPhiSearch, ApiNames.AllPatientsSearch]),
  isFetchingWithSearch: selectIsFetching([ApiNames.PhiSearchFreeText, ApiNames.SearchFreeText])
});

const mapDispatchToProps = (dispatch) => ({
  setTableDataAct: (page, sorter) => dispatch(ACTION.paginateSortChange(page, selectedViews.allPatients, sorter))
});

const AllPatientsTable = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(AllPatientsTableComponent);

export { AllPatientsTable };
