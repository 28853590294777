import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import { Typography, Button, Divider, Space, Tooltip, Table } from "antd";
import { EllipsisOutlined, EyeInvisibleOutlined, EyeOutlined, EditOutlined } from "@ant-design/icons";
import styled, { css } from "styled-components";
import { dateFormatter } from "../../utils/date";
import { DropdownMenu } from "../DropdownMenu";
import { font14pxSB } from "../Typography";
import { useSelector } from "react-redux";
import { SingleSelect } from "../customComponentNewDesign/CustomSelect";
import { PhysicianSelect } from "../Select";
import { TreatmentChangeStatusPopup } from "./TreatmentChangeStatusPopup";
import AddMedicationModal from "../ManagePatientDetails/Medication/MedicationModal/AddMedicationModal";
import EditMedicationModal from "../ManagePatientDetails/Medication/MedicationModal/EditMedicationModal";
import { PRIMARY_50, PRIMARY_600, GRAY_500, GRAY_700, ERROR_950 } from "../../constant/colors";
import { ReactComponent as ShieldCovered } from "../../assets/svg/ShieldCovered.svg";
import { ReactComponent as ShieldUncovered } from "../../assets/svg/ShieldUncovered.svg";
import { ReactComponent as TrashBinIcon } from "../../assets/svg/TrashBin.svg";
import { selectIsSidebarCollapsed, selectProvidersDoctors } from "../../store/selector";
import { isRxNormDrugIVAdministered } from "../../utils/drugs";
import { filterSort } from "../../utils/sort";
import { commonTableStyle } from "../TableSection/TableStyle";

const { Option } = SingleSelect;

const { Text } = Typography;

const flex = css`
  flex: 1;
  display: flex;
  align-items: center;
`;

const EllipsisWrapper = styled.div`
  cursor: pointer;
  width: 20px;
  :hover {
    background-color: ${PRIMARY_50};
    color: ${PRIMARY_600};
    border-radius: 50%;
  }
`;

const TreatmentWrapper = styled.div`
  padding-top: 32px;
`;

const TableTitle = styled.div`
  ${font14pxSB}
  ${flex}
  justify-content: space-between;
  color: ${GRAY_500};
  padding-bottom: 8px;
  span {
    display: flex;
    align-items: center;
    .anticon-question-circle {
      margin-left: 8px;
    }
  }
  .ant-btn-link {
    padding-right: 0;
  }
`;

const StyledTable = styled(Table)`
  ${commonTableStyle()}
  margin-top: 0px;
  .ant-table-empty {
    margin: 0px;
    .ant-table-thead > tr > .ant-table-cell:not(:first-child) {
      min-width: none;
    }
    .ant-table-tbody {
      height: 100%;
    }
  }
  .ant-table-tbody > tr > td {
    padding: 5px 8px 5px 10px !important;
  }

  .ant-table-tbody > tr > td:nth-last-child(1) {
    padding: 5px 8px 5px 10px !important;
  }

  .ant-table-thead > tr > .ant-table-cell {
    white-space: normal;
    .ant-typography {
      color: ${GRAY_700} !important;
    }
  }

  .ant-table-cell {
    white-space: normal;
  }

  .ant-table {
    max-height: 100%;
  }

  .conflicted:hover {
    background-color: ${ERROR_950};
  }

  /* Last three columns are controls, so kill the margins */
  tbody > tr > td.ant-table-cell:nth-last-of-type(-n + 3) {
    margin: 0;
    padding: 0;
    .ant-btn {
      margin: 0;
      padding: 0;
    }
  }
  .isHidden {
    opacity: 0.3;
  }

  .ant-select {
    .ant-space-vertical > .ant-space-item {
      .title {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }

      @media (max-width: 1600px) {
        max-width: ${(props) => (props.isSidebarCollapsed ? "130px" : "70px")};
      }

      @media (min-width: 1600px) {
        max-width: ${(props) => (props.isSidebarCollapsed ? "270px" : "210px")};
      }
    }
  }
`;

const DateHolder = styled.span`
  cursor: default;
  color: ${({ enabled }) => (enabled ? "#000000" : "#cecece")};
`;

const ManagePatientDetailsMedications = ({ treatments, onUpdate = () => {}, onAdd = null, onDelete = null }) => {
  const { t } = useTranslation();

  const showAlertPopupRef = useRef();
  const showAddNewTreatmentPopupRef = useRef();
  const showEditTreatmentPopupRef = useRef();

  const [drugToFilterBy, setDrugToFilterBy] = useState(null);
  const [physicianToFilterBy, setPhysicianToFilterBy] = useState(null);

  const physicians = useSelector(selectProvidersDoctors) || [];
  const { manualTreatments = [], importedTreatments = [] } = treatments;

  const renderOption = (tmDisplayName, tmDisplayNameMaxChars) => {
    if (tmDisplayName.length > tmDisplayNameMaxChars) {
      return (
        <Tooltip title={tmDisplayName}>
          <span>{tmDisplayName.substring(0, tmDisplayNameMaxChars)}...</span>
        </Tooltip>
      );
    }
    return tmDisplayName;
  };

  const isSidebarCollapsed = useSelector(selectIsSidebarCollapsed);

  const tableColumns = (treatmentsType) => [
    {
      title: t("managePatientDetails.drugName"),
      dataIndex: ["rxnormDrug", "tmDisplayName"],
      key: "name",
      width: "auto",
      render: (tmDisplayName) => renderOption(tmDisplayName, 40)
    },
    {
      title: t("managePatientDetails.cycles"),
      dataIndex: "cycles",
      width: 70,
      key: "cycles",
      render: (_, medication) => {
        if (isRxNormDrugIVAdministered({ tmRouteType: medication.rxnormDrug.tmRouteType })) {
          return medication.numOfCycles || 1;
        }
        return medication.numberOfRefillsAuthorized || 1;
      }
    },
    {
      title: t("managePatientDetails.start_date"),
      dataIndex: "startDate",
      key: "startDate",
      width: 120,
      render: (startDate) => {
        return <DateHolder enabled={!!startDate}>{dateFormatter(startDate) || "MM/DD/YYYY"}</DateHolder>;
      }
    },
    {
      title: t("managePatientDetails.end_date"),
      dataIndex: "endDate",
      key: "endDate",
      width: 120,
      render: (endDate) => {
        return <DateHolder enabled={!!endDate}>{dateFormatter(endDate) || "MM/DD/YYYY"}</DateHolder>;
      }
    },
    {
      title: t("managePatientDetails.physician"),
      dataIndex: "physician",
      key: "physician",
      width: "auto",
      // NB: treatment.physician is an object { value: physician.id }, for unknown reasons
      render: (physician, record) => {
        return (
          <PhysicianSelect
            id="treatment_ordering_physician"
            placeholder="No Physician"
            bordered={false}
            value={physician}
            onSelect={(value) => {
              onUpdate(treatmentsType, { physician: value }, record);
            }}
            onClear={() => onUpdate(treatmentsType, { physician: "" }, record)}
          />
        );
      }
    },
    {
      dataIndex: "isUncovered",
      key: "isUncovered",
      width: 35,
      render: (isUncovered) => (isUncovered ? <ShieldUncovered /> : <ShieldCovered />)
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      align: "left",
      width: 40,
      render: (props, record) => {
        const manualActions = [
          {
            id: "set_drug_uncovered",
            key: "set_covered",
            text: record?.isUncovered ? "Set as covered" : "Set as not covered",
            icon: record?.isUncovered ? <ShieldCovered /> : <ShieldUncovered />
          },
          {
            divider: true
          },
          {
            id: "set_drug_hidden",
            key: "set_hidden",
            text: record?.isHidden ? "Show" : "Hide",
            icon: (record?.isHidden && <EyeOutlined />) || <EyeInvisibleOutlined />
          }
        ];
        if (treatmentsType === "manualTreatments") {
          manualActions.unshift({
            id: "edit_medication",
            key: "edit_treatment",
            text: "Edit",
            icon: <EditOutlined />
          });
          manualActions.push({
            id: "delete_medication",
            key: "delete",
            text: "Delete",
            icon: <TrashBinIcon />
          });
        }

        return (
          <DropdownMenu
            menuWidth="190px"
            onClick={(key) => {
              if (key === "edit_treatment") {
                showEditTreatmentPopupRef.current(treatmentsType, record);
              } else if (key === "set_covered") {
                if (!record.isUncovered) {
                  showAlertPopupRef.current("cover", record, () =>
                    onUpdate(treatmentsType, { isUncovered: !record.isUncovered }, record)
                  );
                } else {
                  onUpdate(treatmentsType, { isUncovered: !record.isUncovered }, record);
                }
              } else if (key === "set_hidden") {
                if (!record.isHidden) {
                  showAlertPopupRef.current("hide", record, () =>
                    onUpdate(treatmentsType, { isHidden: !record.isHidden }, record)
                  );
                } else {
                  onUpdate(treatmentsType, { isHidden: !record.isHidden }, record);
                }
              } else if (key === "delete") {
                showAlertPopupRef.current("delete", record, () => onDelete && onDelete(record));
              }
            }}
            menu={manualActions}
          >
            <EllipsisWrapper>
              <EllipsisOutlined style={{ fontSize: "18px" }} />
            </EllipsisWrapper>
          </DropdownMenu>
        );
      }
    }
  ];

  // sensitivity: "base", for our purposes, means case-insensitive (see Intl.Collator docs for more)
  const caseInsensitiveDrugNameSort = (a, b) =>
    a.rxnormDrug?.tmDisplayName?.localeCompare(b.rxnormDrug?.tmDisplayName, { sensitivity: "base" });

  const allTreatments = manualTreatments.concat(importedTreatments).sort(caseInsensitiveDrugNameSort);

  function filterAndSortTreatments(treatments) {
    return filterTreatments(treatments).sort(caseInsensitiveDrugNameSort);
  }

  function filterTreatments(treatments) {
    if (!physicianToFilterBy && !drugToFilterBy) {
      return treatments;
    }
    return treatments.filter(({ rxnormDrug, physician }) => {
      return (
        (!drugToFilterBy || rxnormDrug.id === drugToFilterBy) &&
        (!physicianToFilterBy || physician === physicianToFilterBy)
      );
    });
  }

  const getTreatmentPhysicians = function () {
    const physicianIds = new Set(
      allTreatments.map(({ physician }) => {
        return physician;
      })
    );
    return physicians.filter(({ id }) => physicianIds.has(id)).map((p) => ({ ...p, localSortKey: p.name.trim() }));
  };

  return (
    <>
      <Text id="manage-patient-details-medications" style={{ fontSize: 24 }}>
        {t("managePatientDetails.medications")}
      </Text>
      <Divider />
      <Space direction="horizontal" style={{ display: "flex", justifyContent: "space-between" }}>
        <SingleSelect
          id="manage-patient-details-treatment-drug-filter-list"
          allowClear
          onChange={setDrugToFilterBy}
          placeholder="Search any drug"
          width={250}
          style={{ minWidth: 250, maxWidth: 250 }}
        >
          {_.uniqBy(allTreatments, "rxnormDrug.id").map(({ rxnormDrug }, index) => (
            <Option key={index} value={rxnormDrug.id}>
              {renderOption(rxnormDrug.tmDisplayName, 30)}
            </Option>
          ))}
        </SingleSelect>
        <SingleSelect
          id="manage-patient-details-treatment-physician-filter"
          onChange={setPhysicianToFilterBy}
          allowClear
          width={250}
          style={{ minWidth: 250, maxWidth: 250 }}
          placeholder="Physician"
          options={getTreatmentPhysicians()}
          filterSort={filterSort}
          fieldNames={{ value: "id", label: "name", localSortKey: "localSortKey" }}
        />
      </Space>
      <Divider />
      <TableTitle>
        <span>
          {t("managePatientDetails.medications_section.manually_added_medications")} ({manualTreatments.length})
        </span>
        <Button type="link" onClick={() => showAddNewTreatmentPopupRef.current()}>
          {t("managePatientDetails.medications_section.add_medication")}
        </Button>
      </TableTitle>
      <StyledTable
        id="manage-patient-details-manual-treatment-table"
        pagination={false}
        bordered={false}
        columns={tableColumns("manualTreatments")}
        rowKey="id"
        dataSource={filterAndSortTreatments(manualTreatments)}
        rowClassName={(record) => record.isHidden && "isHidden"}
        isSidebarCollapsed={isSidebarCollapsed}
        tableLayout="fixed"
      />
      <TreatmentWrapper>
        <TableTitle>
          <span>
            {t("managePatientDetails.medications_section.imported_medications")} ({importedTreatments.length})
          </span>
        </TableTitle>
        <StyledTable
          pagination={false}
          bordered={false}
          columns={tableColumns("importedTreatments")}
          rowKey="id"
          dataSource={filterAndSortTreatments(importedTreatments)}
          rowClassName={(record) => record.isHidden && "isHidden"}
        />
      </TreatmentWrapper>
      <TreatmentChangeStatusPopup showPopupRef={showAlertPopupRef} />
      <AddMedicationModal addNewMedication={onAdd} showPopupRef={showAddNewTreatmentPopupRef} />
      <EditMedicationModal updateMedications={onUpdate} showPopupRef={showEditTreatmentPopupRef} />
    </>
  );
};

export default ManagePatientDetailsMedications;
