import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Typography, Divider, Spin, Form } from "antd";
import styled from "styled-components";
import { useSelector } from "react-redux";

import ManagePatientDetailsTabs from "../component/ManagePatientDetails/Tabs";
import ManagePatientDetailsPersonal from "../component/ManagePatientDetails/Personal";
import ManagePatientDetailsMedical from "../component/ManagePatientDetails/Medical";
import ManagePatientDetailsFinancial from "../component/ManagePatientDetails/Financial";
import ManagePatientDetailsMedications from "../component/ManagePatientDetails/Medications";

import { DiagnosisTable } from "../component/ManagePatientDetails/DiagnosisTable";
import { ConflictNavigator } from "../component/ManagePatientDetails/ConflictNavigator";
import { ManagePatientDetailsContext } from "../component/ManagePatientDetails/context";
import ManagePatientDetailsContextProvider from "../component/ManagePatientDetails/context";
import { SubHeader } from "../component/ManagePatientDetails/SubHeader";
import SubSectionHeader from "../component/ManagePatientDetails/SubSectionHeader";
import Coverages from "../component/ManagePatientDetails/Coverages";

import { selectIsFetching } from "../store/selector";
import { ApiNames } from "../api/api";
import { GRAY_50 } from "../constant/colors";

const { Text } = Typography;

const ManagePatientDetailsRow = styled.div`
  filter: ${({ $isFetching }) => ($isFetching && "blur(8px)") || "none"};
  background-color: ${GRAY_50};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 15px 10px 15px;
`;

const ManagePatientDetailsContent = styled(Row)`
  width: 100%;
  background-color: ${GRAY_50};
`;

const ScrollContainer = styled(Col)`
  height: calc(100vh - 200px);
  overflow-y: scroll;
  padding-right: 25px;
`;

const useIsManageDetailsFetching = () => {
  const isFetchingNetworkActions = useSelector(
    selectIsFetching([ApiNames.PatientDetails, ApiNames.PatientDetailsNonPhi, ApiNames.Settings, ApiNames.LoggedUser])
  );

  const { isFetching } = useContext(ManagePatientDetailsContext);

  return isFetchingNetworkActions || isFetching;
};

const FetchingSpinner = () => {
  const isFetching = useIsManageDetailsFetching();

  useEffect(() => {
    if (isFetching) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style = null;
    }

    return () => {
      document.body.style = null;
    };
  }, [isFetching]);

  if (!isFetching) {
    return null;
  }

  return (
    <div
      style={{
        zIndex: 666,
        opacity: 0.4,
        backgroundColor: "black",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export const handleRadioGroupKeyDown = (e, radioLabel, radioRefs) => {
  if (e.key === "Tab") {
    e.preventDefault();
    const radioLabels = Object.keys(radioRefs.current);
    const currentIndex = radioLabels.indexOf(radioLabel);
    const nextIndex = (currentIndex + 1) % radioLabels.length;
    const nextLabel = radioLabels[nextIndex];
    radioRefs.current[nextLabel].focus();
  }
};

const ManagePatientDetailsSubHeader = () => {
  const { handleSubmit, getIsFormModified, isFetching, formWithoutErrors } = useContext(ManagePatientDetailsContext);
  const isFormModified = getIsFormModified();
  const form = Form.useFormInstance();
  Form.useWatch([], form);
  return (
    <SubHeader
      handleSubmit={() => handleSubmit()}
      isFetching={isFetching}
      isFormModified={isFormModified}
      formWithoutErrors={formWithoutErrors}
    />
  );
};

const ManagePatientDetails = () => {
  const { t } = useTranslation();

  const { updateMedications, addNewMedication, deleteTreatments, allDrugEvents } =
    useContext(ManagePatientDetailsContext);
  const containerRef = useRef(null);

  return (
    <>
      <ManagePatientDetailsSubHeader />
      <FetchingSpinner />
      <ManagePatientDetailsRow>
        <ManagePatientDetailsContent>
          <Col span="6">
            <ManagePatientDetailsTabs containerRef={containerRef} />
          </Col>
          <ScrollContainer span="14" ref={containerRef} id="scrollContainer">
            <ConflictNavigator />
            <ManagePatientDetailsPersonal />
            <Divider />
            <Text id="manage-patient-details-financial" style={{ fontSize: 24 }}>
              {t("managePatientDetails.financial")}
            </Text>
            <ManagePatientDetailsFinancial />
            <Divider />
            <Coverages />
            <Divider />

            <Text id="manage-patient-details-medical" style={{ fontSize: 24 }}>
              {t("managePatientDetails.medical")}
            </Text>
            <Divider />
            <DiagnosisTable />
            <SubSectionHeader title="Medical Information" spaceStyle={{ marginTop: "24px" }} />
            <ManagePatientDetailsMedical />
            <Divider />
            <ManagePatientDetailsMedications
              treatments={allDrugEvents}
              onUpdate={updateMedications}
              onAdd={addNewMedication}
              onDelete={deleteTreatments}
            />
            <Divider />
          </ScrollContainer>
        </ManagePatientDetailsContent>
      </ManagePatientDetailsRow>
    </>
  );
};

const ManagePatientDetailsWithContext = () => (
  <ManagePatientDetailsContextProvider>
    <ManagePatientDetails />
  </ManagePatientDetailsContextProvider>
);

export default ManagePatientDetailsWithContext;
