import styled from "styled-components";

import { GRAY_700, GRAY_75, GRAY_50, GRAY11, PRIMARY_600, WHITE } from "../../constant/colors";
import { fontWeights, sizes } from "../../constant/styles";

export const commonTableStyle = (isFilterListView) => `
  margin-top: 10px;

  .ant-table-empty {
    margin: 35px 0px 40px 0px;

    .ant-table-thead > tr > .ant-table-cell:not(:first-child) {
      min-width: 50px;
    }
    .ant-table-tbody {
      height:  calc(100vh - 295px);
    }
  }

  .ant-table-thead {
    height: 36px; 
    max-height: 36px; 
    background: ${GRAY_50};
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .ant-table-thead > tr > .ant-table-cell {
    color: ${GRAY_700};
    font-weight: ${fontWeights.medium};
    font-size: ${sizes.medium};
    line-height: 18px; 
    font-style: normal;
    vertical-align: top;
    white-space: nowrap;
  }

  .ant-table-cell {
    white-space: nowrap;   
    width: auto;  
    max-width: 100%;  
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
    border-left: 1px solid ${GRAY11};
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
    border-right: 1px solid ${GRAY11};
  }

  .ant-table-thead > tr > th {
    border-bottom: 1px solid ${GRAY11};
    border-top: 1px solid ${GRAY11};
    padding:  8px 8px 10px 20px !important; 
  }

  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${GRAY11};
    padding:  5px 0px 5px 20px !important; 
  }

  .ant-table-tbody > tr > td:first-child {
    border-left: 1px solid ${GRAY11};
  }

  .ant-table-tbody > tr > td:last-child {
    border-right: 1px solid ${GRAY11};
  }

  .ant-table-thead > tr > th > .ant-table-column-sorters {
    justify-content: start;
    align-items: center;
  }

  .ant-table-thead > tr > th > .ant-table-column-sorters .ant-table-column-title {
    flex: initial;
  }

  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: ${GRAY_75};
  }

  .ant-table {
    max-height: ${isFilterListView ? "calc(100vh - 285px)" : "calc(100vh - 240px)"};
    overflow: auto;
  }

  .horizontal-scroll {
    overflow-x: auto;
    position: sticky;
    bottom: 0;
    z-index: 2;
    background-color: ${WHITE};
  }

  .ant-pagination {
    position: sticky;
    bottom: 0;
    z-index: 1;
    left: 0;
    width: 100%;
    padding: 0px;
    background-color: ${WHITE};
    text-align: center;
  }

  .ant-table::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .ant-table::-webkit-scrollbar:vertical {
    width: 15px;
  }

  .ant-table::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 4px solid white;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    svg {
      display: none;
    }
  }

  .ant-table-column-sorter-up.active:after {
    content: "";
    display: block;
    width: 2px;
    height: 12px;
    background-color: ${PRIMARY_600};
    transform: rotate(-45deg) translate(3.5px, 0px);
  }

  .ant-table-column-sorter-up.active {
    display: inline-block;
    margin: auto;
    width: 9px;
    height: 9px;
    border-top: 2px solid ${PRIMARY_600};
    border-left: 2px solid ${PRIMARY_600};
    transform-origin: center;
    transform: rotate(45deg);
    top:5px;
    position: relative;
  }

  .ant-table-column-sorter-down.active:after {
    content: "";
    display: block;
    width: 2px;
    height: 12px;
    background-color: ${PRIMARY_600};
    transform: rotate(-45deg) translate(3.5px, 0px);
  }

  .ant-table-column-sorter-down.active {
    display: inline-block;
    margin: -3px auto;
    width: 9px;
    height: 9px;
    border-top: 2px solid ${PRIMARY_600};
    border-left: 2px solid ${PRIMARY_600};
    transform-origin: center;
    transform: rotate(-135deg);
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #f0f0f0;
  }
`;

export const ColumnSelectorContainer = styled.div`
  position: relative;
  top: ${({ isRelevantPatientView, isSearchResultsView }) =>
    isRelevantPatientView || isSearchResultsView ? "0px" : "10px"};
  direction: rtl;
  right: 5px;
`;
