import { Checkbox, Popover, Select, Space, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { debounce } from "lodash";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";

import {
  selectAssigneesAndUserGroups,
  selectProvidersDoctors,
  selectProvidersFacility,
  selectSelectedView,
  selectUser
} from "../../store/selector";
import { searchDiagnosisIcdsAPI, searchDiagnosisNamesAPI } from "../../api/api";
import { filterSort, sortListByOrder, stringSort } from "../../utils/sort";

import { desiredOrderOfStatuses } from "../../constant/sort";
import { useGetSetting } from "../../hooks/getSetting";
import { formatAssignee } from "../../utils/formaters";
import { selectedViews } from "../../constant/table";
import { LIGHT_BLUE, GRAY_400 } from "../../constant/colors";
import { MultiSelect } from "../customComponent/CustomSelect";
import { isEmpty } from "../../utils/string";
import ErrorMessage from "../customComponent/customMessages/ErrorMessage";
import {
  getRelevantPatientsTableConfigFromLocalStorage,
  OOP_MET_STATUS,
  saveRelevantPatientsTableConfigToLocalStorage
} from "../../constant/relevantPatients";
import { DIAGNOSIS_FILTER_ISC_CODE, DIAGNOSIS_FILTER_NAME, FILTER_PATIENT_ASSIGNEE } from "../../constant/filters";

const { Text } = Typography;
const { Option } = Select;

export const RelevantPatientFilters = ({ handleFetchRelevantPatientFilter }) => {
  const { t } = useTranslation();

  const selectedView = useSelector(selectSelectedView);
  const facilities = useSelector(selectProvidersFacility);
  const user = useSelector(selectUser);
  const assignees = useSelector(selectAssigneesAndUserGroups);
  const doctors = useSelector(selectProvidersDoctors);

  const [facilitySearchInput, setFacilitySearchInput] = useState("");
  const [statusSearchInput, setStatusSearchInput] = useState("");
  const [filteredStatuses, setFilteredStatuses] = useState([]);
  const [assigneesSearchInput, setAssigneesSearchInput] = useState("");
  const [physicianSearchInput, setPhysicianSearchInput] = useState("");
  const [diagnosisSearchResults, setDiagnosisSearchResults] = useState([]);
  const [isFetchingDiagnosis, setIsFetchingDiagnosis] = useState(false);
  const [diagnosisIcdsSearchResults, setDiagnosisIcdsSearchResults] = useState([]);
  const [isFetchingDiagnosisIcds, setIsFetchingDiagnosisIcds] = useState(false);

  const [showPhysician, statuses] = useGetSetting(["doctorProviderMode", "statuses"]);

  const relevantPatientsTableConfig = getRelevantPatientsTableConfigFromLocalStorage();
  const relevantPatientsFilters = relevantPatientsTableConfig?.filters || {};

  const validAssignees =
    relevantPatientsFilters.assignees?.length &&
    relevantPatientsFilters.assignees
      .map((assignee) =>
        assignees.find((x) => {
          // Note: assignees are stored as array of options(value, text),
          // but When user change the selected assignees from the dropdown, it will be replaced by list of ids (option.value)
          return typeof assignee !== "object" ? x.id === assignee : x.id === assignee.value;
        })
      )
      .filter((assignee) => assignee)
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
  const assigneesTextToDisplay =
    (validAssignees &&
      validAssignees.length > 0 &&
      validAssignees
        .map(({ firstName, lastName, name, type }) => formatAssignee({ firstName, lastName, name, type }))
        .join(" | ")) ||
    "";

  useEffect(() => {
    if (!statuses.length) {
      setFilteredStatuses([]);
      return;
    }
    const orderedStatuses = sortListByOrder(statuses, desiredOrderOfStatuses, "value");
    if (JSON.stringify(filteredStatuses) !== JSON.stringify(orderedStatuses)) {
      setFilteredStatuses(orderedStatuses);
    }
  }, [filteredStatuses]);

  const onDiagnosisSearch = async (text) => {
    if (isEmpty(text)) return;

    try {
      setIsFetchingDiagnosis(true);
      const result = await searchDiagnosisNamesAPI(text);
      const { data } = result;
      setDiagnosisSearchResults(data.data);
    } catch (error) {
      ErrorMessage(`${t("errorMessages.diagnosis_names")} ${error}`);
    } finally {
      setIsFetchingDiagnosis(false);
    }
  };

  const onDiagnosisIcdsSearch = async (text) => {
    if (isEmpty(text)) return;
    try {
      setIsFetchingDiagnosisIcds(true);
      const result = await searchDiagnosisIcdsAPI(text);
      if (result && result.data && result.data.data) {
        const { data } = result.data;
        setDiagnosisIcdsSearchResults(data);
      } else {
        ErrorMessage(t("errorMessages.dignosis_icds"));
      }
    } catch (error) {
      ErrorMessage(t("errorMessages.dignosis_icds"));
    } finally {
      setIsFetchingDiagnosisIcds(false);
    }
  };

  const updateRelevantPatientsTableConfig = (updatedFilters) => {
    const updatedConfig = {
      ...relevantPatientsTableConfig,
      filters: updatedFilters,
      currentPage: 1
    };

    saveRelevantPatientsTableConfigToLocalStorage(updatedConfig);
    return updatedFilters;
  };

  const handleInputMultiSelect = (values, options, filterName) => {
    const updatedFilters = {
      ...relevantPatientsTableConfig.filters,
      [filterName]: options
    };

    const finalFilters = updateRelevantPatientsTableConfig(updatedFilters);
    handleFetchRelevantPatientFilter(finalFilters);
  };

  const handleCheckUnassigned = (value) => {
    const updatedFilters = {
      ...relevantPatientsTableConfig.filters,
      isUnassigned: value,
      assignees: []
    };

    const finalFilters = updateRelevantPatientsTableConfig(updatedFilters);
    handleFetchRelevantPatientFilter(finalFilters);
  };

  return (
    selectedView === selectedViews.topOpportunity && (
      <Space size={16} direction="vertical" style={{ width: "100%", padding: "0px 16px" }}>
        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Text strong style={{ color: LIGHT_BLUE }}>
            {t("tableFilters.status")}
          </Text>
          <MultiSelect
            id="filter_patient_status"
            placeholder={t("all")}
            onChange={(values, options) => handleInputMultiSelect(values, options, "statuses")}
            value={relevantPatientsFilters.statuses}
            tooltipContent={relevantPatientsFilters.statuses || []}
            autoClearSearchValue={false}
            mode="multiple"
            maxTagCount={1}
            maxTagTextLength={relevantPatientsFilters.statuses?.length > 1 ? 13 : 16}
            dropdownMatchSelectWidth={false}
            bordered={false}
            style={{ width: "100%" }}
            clearAll={true}
            allowClear={true}
            selectAll={true}
            onClearAll={() => {
              handleInputMultiSelect([], [], "statuses");
              setAssigneesSearchInput("");
            }}
            filterOption={(input, option) => {
              setStatusSearchInput(input.toLowerCase());
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onSelectAll={() => {
              const newFilteredStatuses = filteredStatuses.filter(
                (d) =>
                  d.text?.toLowerCase().includes(statusSearchInput) &&
                  d.value.toLowerCase() != OOP_MET_STATUS.toLowerCase()
              );
              const values = newFilteredStatuses.map(({ value }) => value);
              const options = newFilteredStatuses.map(({ value, text }, index) => ({
                value: value,
                key: index,
                children: text
              }));

              handleInputMultiSelect(values, options, "statuses");
            }}
          >
            {filteredStatuses?.length &&
              filteredStatuses
                .filter(
                  (status) =>
                    (!status.roleRequirement || status.roleRequirement == user?.role) &&
                    status.value.toLowerCase() != OOP_MET_STATUS.toLowerCase()
                )
                .map((status) => (
                  <Option key={status.value} value={status.value}>
                    {status.text}
                  </Option>
                ))}
          </MultiSelect>
        </Space>

        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Text strong style={{ color: LIGHT_BLUE }}>
            {t("tableFilters.assignee")}
          </Text>

          <MultiSelect
            id={FILTER_PATIENT_ASSIGNEE}
            tooltipContent={assigneesTextToDisplay}
            onChange={(values, options) => handleInputMultiSelect(values, options, "assignees")}
            disabled={relevantPatientsFilters.isUnassigned}
            value={!validAssignees ? [] : validAssignees.map(({ id }) => id)}
            autoClearSearchValue={false}
            showSearch
            mode="multiple"
            maxTagCount={1}
            maxTagTextLength={relevantPatientsFilters.assignees?.length > 1 ? 13 : 16}
            allowClear
            style={{ width: "100%" }}
            placeholder={relevantPatientsFilters.isUnassigned ? t("unassigned") : `${t("all")}`}
            filterOption={(input, option) => {
              setAssigneesSearchInput(input.toLowerCase());
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            filterSort={(a, b) => {
              const aIsSelected = relevantPatientsFilters.assignees?.indexOf(a.value) > -1;
              const bIsSelected = relevantPatientsFilters.assignees?.indexOf(b.value) > -1;
              if ((aIsSelected && bIsSelected) || (!aIsSelected && !bIsSelected)) {
                return a.children.localeCompare(b.children);
              }
              if (aIsSelected && !bIsSelected) {
                return -1;
              }
              if (!aIsSelected && bIsSelected) {
                return 1;
              }
            }}
            bordered={false}
            onSelectAll={() => {
              const filteredAssignees = assignees.filter((d) =>
                d.displayName?.toLowerCase().includes(assigneesSearchInput)
              );
              const values = filteredAssignees.map(({ id }) => id);
              const options = assignees.map(({ id, displayName, name }, index) => ({
                value: id,
                key: index,
                children: displayName || name
              }));

              handleInputMultiSelect(values, options, "assignees");
            }}
            onClearAll={() => {
              handleInputMultiSelect([], [], "assignees");
              setAssigneesSearchInput("");
            }}
            selectAll
            clearAll
          >
            {assignees.map(({ id, firstName, lastName, name, type }) => (
              <Option key={id} value={id}>
                {formatAssignee({ firstName, lastName, name, type })}
              </Option>
            ))}
          </MultiSelect>

          <Space id="patient_filter_unassigned">
            <Checkbox
              style={{ paddingTop: 4 }}
              checked={relevantPatientsFilters.isUnassigned}
              onChange={(e) => handleCheckUnassigned(e.target.checked)}
            >
              <Text style={{ color: LIGHT_BLUE }}>{t("unassigned")}</Text>
            </Checkbox>
          </Space>
        </Space>

        <Space style={{ width: "100%" }} direction="vertical">
          <Text strong style={{ color: LIGHT_BLUE }}>
            {t("tableFilters.patientFacility")}
          </Text>
          <MultiSelect
            id="filter_patient_facility"
            dropdownMatchSelectWidth={false}
            tooltipContent={relevantPatientsFilters.facilities || []}
            onChange={(values, options) => handleInputMultiSelect(values, options, "facilities")}
            value={relevantPatientsFilters.facilities || []}
            autoClearSearchValue={false}
            showSearch
            mode="multiple"
            maxTagCount={1}
            maxTagTextLength={relevantPatientsFilters.facilities?.length > 1 ? 13 : 16}
            allowClear
            style={{ width: "100%" }}
            placeholder={`${t("all")}`}
            filterOption={(input, option) => {
              setFacilitySearchInput(input.toLowerCase());
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            filterSort={filterSort}
            bordered={false}
            clearAll={true}
            selectAll={true}
            onSelectAll={() => {
              const filteredFacilities = facilities.filter((d) => d.name?.toLowerCase().includes(facilitySearchInput));
              const values = filteredFacilities.map(({ id }) => id);
              const options = facilities.map(({ id, name }, index) => ({
                value: id,
                key: index,
                children: name
              }));
              handleInputMultiSelect(values, options, "facilities");
            }}
            onClearAll={() => {
              handleInputMultiSelect([], [], "facilities");
              setFacilitySearchInput("");
            }}
          >
            {facilities &&
              !!facilities.length &&
              _.uniqBy(facilities, (facility) => facility.name?.toLowerCase().trim()).map((facility, index) => (
                <Option key={index} value={facility.id} localSortKey={facility.name}>
                  {facility.name}
                </Option>
              ))}
          </MultiSelect>
        </Space>

        {showPhysician && (
          <Space size={0} style={{ width: "100%" }} direction="vertical">
            <div style={{ display: "flex" }}>
              <Text strong style={{ color: LIGHT_BLUE }}>
                {t("tableFilters.physician")}
              </Text>
              <div style={{ cursor: "pointer", width: "14px", marginLeft: "10px" }}>
                <Popover
                  content={
                    <div
                      style={{
                        width: "290px",
                        margin: "5px 20px",
                        fontWeight: "400",
                        whiteSpace: "pre-wrap",
                        color: "rgba(0,0,0,.87)"
                      }}
                    >
                      <span>
                        {t("physician_popover.part_one")}
                        <span style={{ textDecoration: "underline", margin: "5px" }}>
                          {t("physician_popover.assigned")}
                        </span>
                        {t("physician_popover.part_two")}
                        <span style={{ fontStyle: "italic", margin: "5px" }}>
                          &quot;{t("physician_popover.visit_schedule")}&quot;
                        </span>
                        {t("physician_popover.part_three")}
                      </span>
                    </div>
                  }
                >
                  <ExclamationCircleOutlined style={{ fontSize: "14px", color: GRAY_400 }} />
                </Popover>
              </div>
            </div>

            <MultiSelect
              id="filter_patient_physician"
              onChange={(values, options) => handleInputMultiSelect(values, options, "doctors")}
              onSelectAll={() => {
                const filteredDoctors = doctors.filter((d) => d.name?.toLowerCase().includes(physicianSearchInput));
                const values = filteredDoctors.map(({ id }) => id);
                const options = doctors.map(({ id, name }, index) => ({
                  value: id,
                  key: index,
                  children: name
                }));
                handleInputMultiSelect(values, options, "doctors");
              }}
              clearAll={true}
              onClearAll={() => {
                handleInputMultiSelect([], [], "doctors");
                setPhysicianSearchInput("");
              }}
              value={relevantPatientsFilters.doctors}
              tooltipContent={relevantPatientsFilters.doctors || []}
              autoClearSearchValue={false}
              showSearch={true}
              mode="multiple"
              maxTagCount={1}
              maxTagTextLength={relevantPatientsFilters.doctors?.length > 1 ? 13 : 16}
              allowClear={true}
              selectAll={true}
              style={{ width: "100%" }}
              placeholder={`${t("all")}`}
              filterOption={(input, option) => {
                setPhysicianSearchInput(input.toLowerCase());
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              filterSort={filterSort}
              bordered={false}
            >
              {doctors &&
                !!doctors.length &&
                _.uniqBy(doctors, (doctor) => doctor.name?.toLowerCase().trim()).map((doctor, index) => (
                  <Option key={index} value={doctor.id} localSortKey={doctor.name}>
                    {doctor.name}
                  </Option>
                ))}
            </MultiSelect>
          </Space>
        )}

        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Space direction="horizontal" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <Text strong style={{ color: LIGHT_BLUE }}>
              {t("tableFilters.diagnosis_name")}
            </Text>
            {!!diagnosisSearchResults?.length && (
              <Text style={{ color: LIGHT_BLUE }}>{diagnosisSearchResults.length} Results</Text>
            )}
          </Space>

          <MultiSelect
            onChange={(values, options) => handleInputMultiSelect(values, options, "diagnosisNames")}
            value={relevantPatientsFilters?.diagnosisNames || []}
            tooltipContent={relevantPatientsFilters.diagnosisNames || []}
            id={DIAGNOSIS_FILTER_NAME}
            placeholder={t("all")}
            allowClear={true}
            showSearch={true}
            loading={isFetchingDiagnosis}
            onSearch={debounce(onDiagnosisSearch, 500)}
            filterOption={false}
            filterSort={(a, b) => stringSort(a.value, b.value)}
            suffixIcon={isFetchingDiagnosis ? <Spin size={"small"} indicator={<LoadingOutlined spin />} /> : <></>}
            notFoundContent={t("not_found")}
            maxTagCount="responsive"
            clearAll={diagnosisSearchResults?.length ? true : false}
            selectAll={diagnosisSearchResults?.length ? true : false}
            onSelectAll={() => {
              const values = diagnosisSearchResults.map(({ value }) => value);
              const options = diagnosisSearchResults.map(({ value, text }, index) => ({
                value: value,
                key: index,
                children: text
              }));
              handleInputMultiSelect(values, options, "diagnosisNames");
            }}
            onClearAll={() => {
              handleInputMultiSelect([], [], "diagnosisNames");
              setDiagnosisSearchResults([]);
            }}
            setDiagnosisSearchResults={setDiagnosisSearchResults}
          >
            {diagnosisSearchResults.map((d, index) => (
              <Option key={index} value={d.value}>
                {d.text}
              </Option>
            ))}
          </MultiSelect>
        </Space>

        <Space size={0} style={{ width: "100%" }} direction="vertical">
          <Space direction="horizontal" style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <Text strong style={{ color: LIGHT_BLUE }}>
              {t("tableFilters.diagnosis_code")}
            </Text>
            {!!diagnosisIcdsSearchResults.length && (
              <Text style={{ color: LIGHT_BLUE }}>{diagnosisIcdsSearchResults.length} Results </Text>
            )}
          </Space>

          <MultiSelect
            id={DIAGNOSIS_FILTER_ISC_CODE}
            onChange={(values, options) => handleInputMultiSelect(values, options, "diagnosisCodeIDs")}
            value={relevantPatientsFilters?.diagnosisCodeIDs || []}
            tooltipContent={relevantPatientsFilters.diagnosisCodeIDs || []}
            placeholder={t("all")}
            allowClear={true}
            showSearch={true}
            loading={isFetchingDiagnosisIcds}
            onSearch={debounce(onDiagnosisIcdsSearch, 500)}
            filterOption={false}
            filterSort={(a, b) => stringSort(a.value, b.value)}
            suffixIcon={isFetchingDiagnosisIcds ? <Spin size={"small"} indicator={<LoadingOutlined spin />} /> : <></>}
            notFoundContent={t("not_found")}
            maxTagCount="responsive"
            clearAll={diagnosisIcdsSearchResults.length ? true : false}
            selectAll={diagnosisIcdsSearchResults.length ? true : false}
            onSelectAll={() => {
              const values = diagnosisIcdsSearchResults.map(({ value }) => value);
              const options = diagnosisIcdsSearchResults.map(({ value, text }, index) => ({
                value: value,
                key: index,
                children: text
              }));
              handleInputMultiSelect(values, options, "diagnosisCodeIDs");
            }}
            onClearAll={() => {
              handleInputMultiSelect([], [], "diagnosisCodeIDs");
              setDiagnosisIcdsSearchResults([]);
            }}
            setDiagnosisIcdsSearchResults={setDiagnosisIcdsSearchResults}
          >
            {diagnosisIcdsSearchResults.map((d, index) => (
              <Option key={index} value={d.value}>
                {d.text}
              </Option>
            ))}
          </MultiSelect>
        </Space>
      </Space>
    )
  );
};
