import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { Table, Typography, Popover } from "antd";
import { withTranslation } from "react-i18next";
import moment from "moment-timezone";
import { withRouter } from "react-router";
import styled from "styled-components";

import { selectTableColumns, selectIsFetching, selectVisitScheduleData } from "../../store/selector";
import { ApiNames } from "../../api/api";
import ACTION from "../../store/action";
import { PAGE_SIZE, selectedViews } from "../../constant/table";
import { TailormedSpinner } from "../TailormedSpinner/TailormedSpinner";
import { ColumnSelector } from "../ColumnSelector";
import { EditColumn, PatientNameColumn, StarColumn, withSorting, AssigneeColumn } from "./CommonColumns";
import PatientPageCount from "./PatientPageCount";
import { TabsMap } from "../../screen/patient/Patient";
import { ColumnSelectorContainer, commonTableStyle } from "./TableStyle";
import { dateFormatter, DATE_FORMAT, dateTimeFormatter, utcDateFormatter, DATE_TIME_FORMAT } from "../../utils/date";
import { patientInsuranceCell, PatientStatusCell } from "../TableCells";
import { SUPPORTED_COVERAGES } from "../../constant/patient";
import { formatPhoneNumber, formatSSN } from "../../utils/formaters";
import { DeceasedLable } from "../DeceasedLable";
import EmptyTableMessage from "./EmptyTableMessage";
import { fetchTableData } from "../../utils/fetchTableData";

const StyledTable = styled(Table)`
  ${(props) => commonTableStyle(props.isFilterListView)}
`;
const { Text } = Typography;

const VisitScheduleTableComponent = ({
  tableColumns,
  isFetching,
  setTableDataAct,
  history,
  tableData,
  t,
  updateSavedFilterViewParameters,
  isFilterListView
}) => {
  const [hoveringLineIndex, setHoveringLineIndex] = useState(null);

  const onClickTable = () => ({
    onCell: (patient) => ({
      onClick: () => {
        const { patientId, journeyId } = patient;
        history.push(TabsMap.overview.path(patientId, journeyId));
      }
    })
  });

  const totalRecordsCount = tableData?.totalPatients;
  const sortingEnabled = tableData?.sortingEnabled;

  const columns = [
    {
      dataIndex: tableColumns[selectedViews.visitSchedule]?.star?.dataIndex,
      key: tableColumns[selectedViews.visitSchedule]?.star?.key,
      width: 35,
      render: (text, record) => <StarColumn record={record} />
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.name_id, {
      minWidth: 300,
      sorterTooltipText: t("columns.sortById"),
      render: (text, record) => <PatientNameColumn record={record} />,
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.allPatients]?.isDeceased, {
      render: (_, record) => (record.isDeceased ? <DeceasedLable /> : ""),
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.visitSchedule]?.phone_number,
      render: (text, record) => formatPhoneNumber(record.phone_number),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.visitSchedule]?.dob,
      render: (text, record) => {
        if (!record.dob || record.dob === t("invalid_date")) {
          return "";
        } else {
          const dob = moment(record.dob);
          if (dob.isValid()) {
            return dob.format(DATE_FORMAT);
          } else {
            return "";
          }
        }
      },
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.visitSchedule]?.ssn,
      render: (text, record) => formatSSN(record.social_security_number),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.visitSchedule]?.primaryDiagnosis,
      render: (text, record) => (record.diagnosises[0] ? record.diagnosises[0] : null),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.visitSchedule]?.assignee,
      width: 230,
      render: (text, record) => <AssigneeColumn record={record} />,
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.visitFacility, {
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.facility, {
      ...onClickTable()
    }),
    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.physician, {
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.visitSchedule]?.employer,
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.visitSchedule]?.adminDate,
      render: (text, record) =>
        record.adminDates ? (
          <Popover
            overlayInnerStyle={{ padding: "20px" }}
            content={
              <div>
                <Text>{t("popover_visit_schedule")}</Text>
                <ul style={{ marginTop: "20px" }}>
                  {record.adminDates.map((itemDates, keyDates) => (
                    <li key={keyDates}>
                      <Text style={{ fontWeight: "bold" }}>{dateTimeFormatter(itemDates)}</Text>
                      {record.adminTypes &&
                        record.adminTypes.map(
                          (itemTypes, keyTypes) => keyDates === keyTypes && <Text> - {itemTypes}</Text>
                        )}
                    </li>
                  ))}
                </ul>
              </div>
            }
          >
            <p
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: "170px",
                overflow: "hidden"
              }}
            >
              {record.adminDates.map((item, key) =>
                key + 1 === record.adminDates.length ? dateTimeFormatter(item) : dateTimeFormatter(item) + " & "
              )}
            </p>
          </Popover>
        ) : (
          utcDateFormatter(record.adminDate, DATE_TIME_FORMAT)
        ),
      ...onClickTable()
    },

    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.adminType, {
      ...onClickTable()
    }),

    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.creationDate, {
      render: (text, record) => dateTimeFormatter(record.creationDate, "MMM D, YYYY"),
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.visitSchedule]?.agreementSignatureDate,
      render: (text, record) => record.agreement_signature_date && dateFormatter(record.agreement_signature_date)
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.patientStatus, {
      render: (_, record) => <PatientStatusCell value={record.status} />,
      ...onClickTable()
    }),
    {
      ...tableColumns[selectedViews.visitSchedule]?.primaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.primary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.visitSchedule]?.secondaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.secondary),
      ...onClickTable()
    },
    {
      ...tableColumns[selectedViews.visitSchedule]?.tertiaryInsurancePlan,
      render: patientInsuranceCell(SUPPORTED_COVERAGES.tertiary),
      ...onClickTable()
    },
    withSorting(sortingEnabled, tableColumns[selectedViews.visitSchedule]?.followupDate, {
      render: (text, record) => record.followupDate && dateFormatter(record.followupDate)
    }),
    {
      dataIndex: tableColumns[selectedViews.visitSchedule]?.edit?.dataIndex,
      key: tableColumns[selectedViews.visitSchedule]?.edit?.key,
      render: (_, record, rowIndex) => <EditColumn isHovering={hoveringLineIndex === rowIndex} record={record} />
    }
  ];

  return (
    <>
      {!!tableData?.patients.length && (
        <ColumnSelectorContainer>
          <ColumnSelector />
        </ColumnSelectorContainer>
      )}
      <StyledTable
        size="small"
        rowClassName="cursor-pointer"
        style={{ width: "100%" }}
        loading={{ indicator: <TailormedSpinner />, spinning: isFetching }}
        columns={columns.filter((col) => tableColumns[selectedViews.visitSchedule]?.[col.key]?.display)}
        locale={{
          emptyText: <EmptyTableMessage />
        }}
        onRow={(_, rowIndex) => {
          return {
            onMouseEnter: () => setHoveringLineIndex(rowIndex),
            onMouseLeave: () => setHoveringLineIndex(null)
          };
        }}
        onChange={(pagination, filters, sorter) =>
          fetchTableData(pagination, sorter, tableData, setTableDataAct, updateSavedFilterViewParameters)
        }
        isFilterListView={isFilterListView}
        pagination={{
          size: "small",
          showSizeChanger: false,
          current: tableData && tableData.selectedPage,
          defaultPageSize: PAGE_SIZE,
          pageSize: PAGE_SIZE,
          position: ["bottom"],
          total: totalRecordsCount,
          showTotal: (total, range) => (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <PatientPageCount total={total} range={range} isAccurateCount={tableData.isAccurateCount} />
            </div>
          )
        }}
        dataSource={
          tableData && tableData.patients
            ? tableData.patients
                .map((patient, index) => ({ ...patient, key: index }))
                .sort((a, b) => b.isActive - a.isActive)
            : null
        }
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  tableColumns: selectTableColumns,
  isFetching: selectIsFetching([ApiNames.VisitSchedulePhiSearch, ApiNames.VisitScheduleSearch]),
  tableData: selectVisitScheduleData
});

const mapDispatchToProps = (dispatch) => ({
  setTableDataAct: (page, sorter) => dispatch(ACTION.paginateSortChange(page, selectedViews.visitSchedule, sorter))
});

const VisitScheduleTable = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(VisitScheduleTableComponent);

export { VisitScheduleTable };
